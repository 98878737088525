<template>
  <div>

    <el-card v-if="list.length > 0">
      <h4>{{ $t('我的模考') }}</h4>
      <div class="margin-t">

        <div v-for="(item, index1) in list" :key="index1" @click="to1(item)" :class="{ 'bb': index1 < list.length - 1 }"
          class="mock-item on">
          <template v-if="item.mock">
            <div class="flex flex-between flex-v-center" style="height:100%">
              <div>
                <h5>{{ item.mock.flag == 1 ? `【${$t('VIP')}】` : `【${$t('免费')}】` }}{{ item.mock.title }}</h5>
                <div class="flex flex-v-center margin-t">
                  <div style="font-size: 12px;margin-left:8px;">{{ $t('开始时间') }}：{{ item.updated_at }}</div>
                  <div v-if="item.status >0" style="font-size: 12px;margin-left:40px;">{{ $t('完成时间') }}：{{ item.finished_at }}</div>

                </div>
              </div>
              <div class="flex flex-v-center">
                <h5 v-if="item.status == 1">【{{ $t('已完成') }}】</h5>
                <h5 v-if="item.status == 3">【{{ $t('已评分') }}】</h5>
                <h5 v-else-if="item.status == 2">【{{ $t('打分中') }}】</h5>
                <h5 v-else-if="item.status == 1">【{{ $t('未打分') }}】</h5>
                <h5 v-else>【{{ $t('进行中') }}】</h5>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </template>
        </div>
      </div>
    </el-card>


    <el-card class="margin-lt" >
      <h4>{{ $t('PTE模考') }}</h4>
      <el-tabs :stretch="true" class="margin-t" @tab-click="handleClick" v-model="selectedTab">
        <el-tab-pane v-for="(type, index) in $store.state.mockTypes" :key="index" :label="type.title"
          :name="index.toString()">
          <div :class="{ 'bb': index1 < type.items.length - 1 }" :id="type.title" v-for="(item, index1) in type.items"
            :key="index1" @click="to(item)" class="mock-item">
            <div class="flex flex-between flex-v-center" style="height:100%">
              <div class="flex flex-v-center">
                <h5>{{ item.flag == 1 ? `【VIP】` : `【${$t('免费')}】` }}{{ item.title }}</h5>
              </div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog top="20%" custom-class="br" :visible.sync="centerDialogVisible" :close-on-click-modal="false"
      :show-close="false" width="30%" center>
      <h4 slot="title">{{ $t('正在进行') }}</h4>

      <div class="center st1">{{ item.mock.title }}</div>

      <span slot="footer" class="dialog-footer center">
        <el-button size="small" @click="centerDialogVisible = false">{{ $t('取 消') }}</el-button>
        <el-button size="small" class="margin-x" type="danger" @click="drop">{{ $t('放 弃') }}</el-button>
        <el-button size="small" type="primary" @click="to(item.mock)">{{ $t('继 续') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedTab: localStorage.getItem("mocks_tab") || "0",
      types: [],
      list: [],
      centerDialogVisible: false,
      item: { mock: {} }
    };
  },

  created() {
    this.$store.dispatch("fetch_mock_types");
    this.query();
  },

  methods: {
    handleClick() {
      localStorage.setItem("mocks_tab", this.selectedTab)
    },
    query() {
      this.$http.post("/api/mock/record/list").then((list) => {
        this.list = list;
      })
    },

    to1(item) {
      if (item.status == 0) {
        this.item = item;
        this.centerDialogVisible = true;
      } else {
        this.$router.push("/main/mock/record?id=" + item.id);
      }
    },

    drop() {
      this.centerDialogVisible = false;
      this.$confirm('', this.$t('确认放弃此次模考记录') + '?', {
        center: true,
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'primary'
      }).then(() => {
        this.$http.post("/api/mock/record/remove", { id: this.item.id }).then(() => {
          this.query();
        })
      });
    },

    to(item) {
      this.centerDialogVisible = false;
      if (item.flag == 1 && this.$store.getters.getRole() == 0) {
        this.$vip_alert("此考卷仅限VIP使用", "")
      } else {
        let url = this.$router.resolve({
          path: '/mock',
          query: { id: item.id }
        })
        window.open(url.href, '_blank')
      }
    }
  },
};
</script>

<style scope>
.mock-item {
  cursor: pointer;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.mock-item.bb {
  margin-bottom: 15px;
}

.mock-item:hover,
.mock-item.on {
  border: 1px solid #e6a13c;
  color: #e6a13c;
}
</style>
